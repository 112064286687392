import { parseStep } from "@utils/onboarding";
import { validateTemplateMessages } from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

// Choose between validate two or three orders steps
export const templateStepsOrderValidationsRules = {
  BETWEEN_TWO_STEPS: "BETWEEN_TWO_STEPS",
  BETWEEN_THREE_STEPS: "BETWEEN_THREE_STEPS",
};

// If the list has only one item, return a string, otherwise return the entire list
export const formatListContainsOnlyOneItem = (arr = []) => {
  return !!arr && arr?.length === 1 ? arr[0] : arr;
};

// Steps validations based on each template flow
export const filterFlowsStepsIndexes = (onboardingOptionsSteps = [], stepName = "", defaultFlowName = "") => {
  let flowsStepsIndexes = [];

  if (!!onboardingOptionsSteps?.length) {
    onboardingOptionsSteps?.forEach((step, stepIndex) => {
      // filtering steps by "stepName"
      if (isEqual(parseStep(step?.name)[0], stepName)) {
        // if the step is into a flow, then add each flow name and the respective step index
        if (step?.flow?.length >= 1) {
          flowsStepsIndexes = [
            ...flowsStepsIndexes,
            ...step?.flow?.map((_flow) => !!_flow && { flow: _flow, stepIndex }),
          ];
        } else if (!step?.flow) {
          // if the step isn't into a flow, then add the flow as default and the respective step index
          flowsStepsIndexes = [...flowsStepsIndexes, { flow: defaultFlowName, stepIndex }];
        }
      }
    });
  }

  return flowsStepsIndexes?.filter((_flowsStepsIndexes) => !!_flowsStepsIndexes);
};
/* Methods to validate template steps order between two steps */

// Main method
const validateTemplateStepsOrderBetweenTwoSteps = (
  mainStepsIndexes = [],
  secondaryStepsIndexes = [],
  templateValidations = () => {},
) => {
  let invalidStepsOrderFlows = [];

  // validate the secondary step based on main steps orders
  mainStepsIndexes?.forEach((mainFlowStepIndex) => {
    secondaryStepsIndexes?.forEach((secondaryFlowStepIndex) => {
      // if in the same flow has the main and the secondary step
      if (isEqual(mainFlowStepIndex?.flow, secondaryFlowStepIndex?.flow)) {
        const flowNameIsAlreadyInInvalidStepsOrderFlows = invalidStepsOrderFlows?.some((invalidStepsOrderFlow) =>
          invalidStepsOrderFlow?.flow?.includes(secondaryFlowStepIndex?.flow),
        );

        // if the flow isn't in the list of invalid flows, add it
        if (!flowNameIsAlreadyInInvalidStepsOrderFlows) {
          invalidStepsOrderFlows.push({
            flow: secondaryFlowStepIndex?.flow,
            mainStepIndex: mainFlowStepIndex?.stepIndex,
            secondaryStepIndex: secondaryFlowStepIndex?.stepIndex,
          });
        }
      }
    });
  });

  const mainFlowsNamesSteps = mainStepsIndexes?.map((mainFlowNameStep) => mainFlowNameStep?.flow);
  const secondaryFlowsNamesSteps = secondaryStepsIndexes?.map((secondaryFlowNameStep) => secondaryFlowNameStep?.flow);
  const mainStepIsIntoTheSameSecondaryFlow = mainStepsIndexes?.some((mainFlowStepIndex) =>
    secondaryFlowsNamesSteps?.includes(mainFlowStepIndex?.flow),
  );

  if (!mainStepIsIntoTheSameSecondaryFlow) {
    const mainFlowsStepsThatAreNotAtAnySecondFlowsSteps = mainStepsIndexes?.filter(
      (mainFlowStepIndex) => !secondaryFlowsNamesSteps?.includes(mainFlowStepIndex?.flow),
    );
    const secondaryFlowsStepsThatAreNotAtAnyFirstFlowsSteps = secondaryStepsIndexes?.filter(
      (secondaryFlowStepIndex) => !mainFlowsNamesSteps?.includes(secondaryFlowStepIndex?.flow),
    );

    if (!!secondaryFlowsStepsThatAreNotAtAnyFirstFlowsSteps?.length) {
      const mainFlowStepsIndexes = mainFlowsStepsThatAreNotAtAnySecondFlowsSteps?.map(
        (mainFlowStep) => mainFlowStep.stepIndex,
      );

      // for each secondary flow step that is not the same as at least one main step flows, validate steps orders
      secondaryFlowsStepsThatAreNotAtAnyFirstFlowsSteps?.forEach((flowStep) => {
        if (
          !!mainFlowStepsIndexes?.length &&
          mainFlowStepsIndexes?.some((mainFlowStepIndex) => flowStep?.stepIndex < mainFlowStepIndex)
        ) {
          templateValidations.push({ flow: flowStep?.flow });
        }
      });
    }
  }

  if (!invalidStepsOrderFlows?.length) {
    return;
  }

  invalidStepsOrderFlows?.forEach((invalidStepsOrderFlow) => {
    const mainStepIndex = invalidStepsOrderFlow?.mainStepIndex;
    const secondaryStepIndex = invalidStepsOrderFlow?.secondaryStepIndex;

    // if the secondary step is before the main step index, add the flow name to the list of invalid flows
    if (secondaryStepIndex < mainStepIndex) {
      templateValidations.push({ flow: invalidStepsOrderFlow?.flow });
    }
  });
};

/* Methods to validate template steps order between three steps */

// Auxiliaries methods
const validateSecondaryStepBasedOnMainStepsOrder = (
  secondaryStepsIndexes = [],
  mainFlowStepIndex = {},
  invalidStepsOrderFlows = [],
) => {
  if (!secondaryStepsIndexes?.length) {
    return;
  }

  secondaryStepsIndexes?.forEach((secondaryFlowStepIndex) => {
    if (isEqual(mainFlowStepIndex?.flow, secondaryFlowStepIndex?.flow)) {
      const secondaryFlowIsNotInInvalidStepsOrder =
        !!invalidStepsOrderFlows?.length &&
        !invalidStepsOrderFlows?.some((invalidStepsOrderFlow) =>
          invalidStepsOrderFlow?.flow?.includes(secondaryFlowStepIndex?.flow),
        );

      if (secondaryFlowIsNotInInvalidStepsOrder || !invalidStepsOrderFlows?.length) {
        const tertiaryInvalidOrderFlowSteps = invalidStepsOrderFlows?.find(
          (invalidStepOrderFlow) => invalidStepOrderFlow?.flow === secondaryFlowStepIndex?.flow,
        );

        // if the tertiary step is already in the list of invalid flows order, just add the secondaryStepIndex
        if (!!tertiaryInvalidOrderFlowSteps) {
          const invalidStepsOrderFlowsUpdated = invalidStepsOrderFlows?.map((invalidStepOrderFlow) => {
            if (invalidStepOrderFlow?.flow === tertiaryInvalidOrderFlowSteps?.flow) {
              return {
                ...tertiaryInvalidOrderFlowSteps,
                secondaryStepIndex: secondaryFlowStepIndex?.stepIndex,
              };
            }
            return invalidStepOrderFlow;
          });

          invalidStepsOrderFlows = invalidStepsOrderFlowsUpdated;
          return;
        }

        invalidStepsOrderFlows.push({
          flow: secondaryFlowStepIndex?.flow,
          mainStepIndex: mainFlowStepIndex?.stepIndex,
          secondaryStepIndex: secondaryFlowStepIndex?.stepIndex,
          tertiaryStepIndex: null,
        });
      }
    }
  });
};

const validateTertiaryStepBasedOnMainStepsOrder = (
  tertiaryStepsIndexes = [],
  mainFlowStepIndex = {},
  invalidStepsOrderFlows = [],
) => {
  if (!tertiaryStepsIndexes?.length) {
    return;
  }

  let invalidStepsOrderFlowsUpdated = [];

  tertiaryStepsIndexes?.forEach((tertiaryFlowStepIndex) => {
    if (isEqual(mainFlowStepIndex?.flow, tertiaryFlowStepIndex?.flow)) {
      const tertiaryFlowIsNotInInvalidStepsOrder =
        !!invalidStepsOrderFlows?.length &&
        !invalidStepsOrderFlows
          .map((is) => typeof is?.tertiaryStepIndex === "number" && is?.tertiaryStepIndex)
          ?.some((is) => isEqual(is?.tertiaryStepIndex, tertiaryFlowStepIndex?.stepIndex));

      if (tertiaryFlowIsNotInInvalidStepsOrder || !invalidStepsOrderFlows?.length) {
        const secondaryInvalidOrderFlowSteps = invalidStepsOrderFlows?.find(
          (invalidStepOrderFlow) => invalidStepOrderFlow?.flow === tertiaryFlowStepIndex?.flow,
        );

        // if the secondary step is already in the list of invalid flows order, just add the tertiaryStepIndex
        if (!!secondaryInvalidOrderFlowSteps) {
          const _invalidStepsOrderFlowsUpdated = invalidStepsOrderFlows
            ?.map((invalidStepOrderFlow) => {
              if (invalidStepOrderFlow?.flow === secondaryInvalidOrderFlowSteps?.flow) {
                return {
                  ...secondaryInvalidOrderFlowSteps,
                  tertiaryStepIndex: tertiaryFlowStepIndex?.stepIndex,
                };
              }

              return invalidStepOrderFlow;
            })
            ?.filter((is) => typeof is?.tertiaryStepIndex === "number" && typeof is?.secondaryStepIndex === "number");

          invalidStepsOrderFlowsUpdated = _invalidStepsOrderFlowsUpdated;
        }

        invalidStepsOrderFlows.push({
          flow: tertiaryFlowStepIndex?.flow,
          mainStepIndex: mainFlowStepIndex?.stepIndex,
          secondaryStepIndex: null,
          tertiaryStepIndex: tertiaryFlowStepIndex?.stepIndex,
        });
      }
    }
  });

  return invalidStepsOrderFlowsUpdated;
};

const validateSecondaryAndTertiaryStepsOrderBasedOnMainStepThatHaveDifferentFlows = (
  mainStepsIndexes = [],
  secondaryStepsIndexes = [],
  tertiaryStepsIndexes = [],
  templateValidations = [],
) => {
  const mainFlowsNamesSteps = mainStepsIndexes?.map((mainFlowNameStep) => mainFlowNameStep?.flow);
  const secondaryFlowsNamesSteps = secondaryStepsIndexes?.map((secondaryFlowNameStep) => secondaryFlowNameStep?.flow);
  const tertiaryFlowsNamesSteps = tertiaryStepsIndexes?.map((tertiaryFlowNameStep) => tertiaryFlowNameStep?.flow);

  const mainStepIsIntoTheSameSecondaryFlow =
    !!secondaryFlowsNamesSteps?.length &&
    !mainStepsIndexes?.some((mainFlowStepIndex) => secondaryFlowsNamesSteps?.includes(mainFlowStepIndex?.flow));
  const mainStepIsIntoTheSameTertiaryFlow =
    !!tertiaryFlowsNamesSteps?.length &&
    !mainStepsIndexes?.some((mainFlowStepIndex) => tertiaryFlowsNamesSteps?.includes(mainFlowStepIndex?.flow));

  if (mainStepIsIntoTheSameSecondaryFlow || mainStepIsIntoTheSameTertiaryFlow) {
    const mainFlowsStepsThatAreNotAtAnySecondaryFlowsSteps = mainStepsIndexes?.filter(
      (mainFlowStepIndex) => !secondaryFlowsNamesSteps?.includes(mainFlowStepIndex?.flow),
    );
    const mainFlowsStepsThatAreNotAtAnyTertiaryFlowsSteps = mainStepsIndexes?.filter(
      (mainFlowStepIndex) => !tertiaryFlowsNamesSteps?.includes(mainFlowStepIndex?.flow),
    );

    const secondaryFlowsStepsThatAreNotAtAnyMainFlowsSteps = secondaryStepsIndexes?.filter(
      (secondaryFlowStepIndex) => !mainFlowsNamesSteps?.includes(secondaryFlowStepIndex?.flow),
    );
    const tertiaryFlowsStepsThatAreNotAtAnyMainFlowsSteps = tertiaryStepsIndexes?.filter(
      (tertiaryFlowStepIndex) => !mainFlowsNamesSteps?.includes(tertiaryFlowStepIndex?.flow),
    );

    if (!!secondaryFlowsStepsThatAreNotAtAnyMainFlowsSteps?.length) {
      const mainFlowStepsIndexes = mainFlowsStepsThatAreNotAtAnySecondaryFlowsSteps?.map(
        (mainFlowStep) => mainFlowStep.stepIndex,
      );

      // for each secondary flow step that are not in the same flow that main flow step, validate steps orders
      secondaryFlowsStepsThatAreNotAtAnyMainFlowsSteps?.forEach((flowStep) => {
        const someSecondaryStepIsBeforeMainStepInDifferentFlows = mainFlowStepsIndexes?.some(
          (mainFlowStepIndex) => flowStep?.stepIndex < mainFlowStepIndex,
        );

        if (!!mainFlowStepsIndexes?.length && someSecondaryStepIsBeforeMainStepInDifferentFlows) {
          templateValidations.push({ flow: flowStep?.flow });
        }
      });
    }

    if (!!tertiaryFlowsStepsThatAreNotAtAnyMainFlowsSteps?.length) {
      const mainFlowStepsIndexes = mainFlowsStepsThatAreNotAtAnyTertiaryFlowsSteps?.map(
        (mainFlowStep) => mainFlowStep.stepIndex,
      );

      // for each tertiary flow step that are not in the same flow that main flow step, validate steps orders
      tertiaryFlowsStepsThatAreNotAtAnyMainFlowsSteps?.forEach((flowStep) => {
        const someTertiaryStepIsBeforeMainStepInDifferentFlows = mainFlowStepsIndexes?.some(
          (mainFlowStepIndex) => flowStep?.stepIndex < mainFlowStepIndex,
        );

        if (!!mainFlowStepsIndexes?.length && someTertiaryStepIsBeforeMainStepInDifferentFlows) {
          templateValidations.push({ flow: flowStep?.flow });
        }
      });
    }
  }
};

// Main method
const validateTemplateStepsOrderBetweenThreeSteps = (
  mainStepsIndexes = [],
  secondaryStepsIndexes = [],
  tertiaryStepsIndexes = [],
  templateValidations = () => {},
) => {
  let invalidStepsOrderFlows = [];

  mainStepsIndexes?.forEach((mainFlowStepIndex) => {
    // validate the secondary step based on main steps orders
    validateSecondaryStepBasedOnMainStepsOrder(secondaryStepsIndexes, mainFlowStepIndex, invalidStepsOrderFlows);

    // validate the tertiary step based on main steps orders
    const invalidStepsOrderFlowsUpdated = validateTertiaryStepBasedOnMainStepsOrder(
      tertiaryStepsIndexes,
      mainFlowStepIndex,
      invalidStepsOrderFlows,
    );

    if (!!invalidStepsOrderFlowsUpdated?.length) {
      invalidStepsOrderFlows = invalidStepsOrderFlowsUpdated;
    }
  });

  /* If the second and/or the third step order is invalid towards the first step rule,
      in the case that the second and/or third step is in flow "A" and the first step in a different flow, for example: flow "B" */
  validateSecondaryAndTertiaryStepsOrderBasedOnMainStepThatHaveDifferentFlows(
    mainStepsIndexes,
    secondaryStepsIndexes,
    tertiaryStepsIndexes,
    templateValidations,
  );

  // validate secondary and tertiary steps orders based on main steps orders, if has invalid flows orders, add a new template validation
  if (!invalidStepsOrderFlows?.length) {
    return;
  }

  invalidStepsOrderFlows?.forEach((invalidStepsOrderFlow) => {
    const mainInvalidStepIndex = invalidStepsOrderFlow?.mainStepIndex;
    const secondaryInvalidStepIndex = invalidStepsOrderFlow?.secondaryStepIndex;
    const tertiaryInvalidStepIndex = invalidStepsOrderFlow?.tertiaryStepIndex;

    if (
      (typeof secondaryInvalidStepIndex === "number" && secondaryInvalidStepIndex < mainInvalidStepIndex) ||
      (typeof tertiaryInvalidStepIndex === "number" && tertiaryInvalidStepIndex < mainInvalidStepIndex)
    ) {
      templateValidations.push({ flow: invalidStepsOrderFlow?.flow });
    }
  });
};

// Return a list that contains the template validations based on templateStepsOrderValidationsRules
export const validateTemplateStepsOrderBasedOnEachFlow = (flowsStepsIndexesArrays = [], templateValidation = "") => {
  const mainStepsIndexes = flowsStepsIndexesArrays[0];
  const secondaryStepsIndexes = flowsStepsIndexesArrays[1];
  const tertiaryStepsIndexes = flowsStepsIndexesArrays[2];

  let templateValidations = [];

  if (
    templateValidation === templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS &&
    flowsStepsIndexesArrays?.every((_flowsStepsIndexesArray) => !!_flowsStepsIndexesArray?.length)
  ) {
    validateTemplateStepsOrderBetweenTwoSteps(mainStepsIndexes, secondaryStepsIndexes, templateValidations);
  } else if (
    templateValidation === templateStepsOrderValidationsRules.BETWEEN_THREE_STEPS &&
    !!mainStepsIndexes?.length &&
    (!!secondaryStepsIndexes?.length || !!tertiaryStepsIndexes?.length)
  ) {
    validateTemplateStepsOrderBetweenThreeSteps(
      mainStepsIndexes,
      secondaryStepsIndexes,
      tertiaryStepsIndexes,
      templateValidations,
    );
  }

  return templateValidations;
};

// Validate if a step is in front of another step on template and if the main step requires that a secondary step is on template
export const validateStepThatRequiresAnotherStep = (
  mainStepValidation = [],
  mainStepValidationFlows = [],
  secondaryStepValidationFlows = [],
  templateValidationNotificationsToAdd = () => [],
  templateValidation = "",
) => {
  let mainStepValidationFlowsNames = [];

  if (!!mainStepValidation?.length) {
    // return the flows names of the main step
    const mainStepValidationFlows = mainStepValidation?.map((validation) => validation?.flow);

    mainStepValidationFlowsNames = mainStepValidationFlows;
  }

  if (!!mainStepValidationFlows?.length) {
    mainStepValidationFlows?.forEach((mainStepValidationFlow) => {
      const secondStepFlow = secondaryStepValidationFlows?.find((secondaryStepValidationFlow) =>
        isEqual(secondaryStepValidationFlow, mainStepValidationFlow),
      );

      // if the secondary step in not in the same flow as the main step
      if (!secondStepFlow) {
        mainStepValidationFlowsNames.push(mainStepValidationFlow);
      }
    });
  }

  if (!!mainStepValidationFlowsNames?.length && mainStepValidationFlowsNames?.every((flowName) => !!flowName)) {
    mainStepValidationFlowsNames = formatListContainsOnlyOneItem(mainStepValidationFlowsNames);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidation)(mainStepValidationFlowsNames),
    );
  }
};

/* Validate if a step has a specific field, for example: "phone_number" at DATA_CONFIRMATION step and
  if this field is required based on a secondary step validation
*/
export const validateIfStepHasSpecificFieldBasedOnSecondaryStep = (
  mainStepValidationFlows = [],
  fieldName = "",
  stepNameThatRequiredSpecificField = "",
  onboardingOptionsSteps = [],
  templateValidationNotificationsToAdd = () => [],
  templateValidation = "",
  defaultFlowName = "",
) => {
  let mainStepValidationFlowsNames = [];

  if (!!mainStepValidationFlows?.length) {
    let flowsStepsFields = [];

    if (!!onboardingOptionsSteps?.length) {
      onboardingOptionsSteps?.forEach((_step) => {
        // filtering steps by "stepNameThatRequiredSpecificField"
        if (isEqual(parseStep(_step?.name)[0], stepNameThatRequiredSpecificField)) {
          // if the step is into a flow, then add each flow name and the array of fields
          if (_step?.flow?.length >= 1) {
            flowsStepsFields = [
              ...flowsStepsFields,
              ..._step?.flow?.map((_flow) => !!_flow && { flow: _flow, fields: _step?.fields }),
            ];
          } else if (!_step?.flow) {
            // if the step isn't into a flow, then add the flow as default and the array of fields
            flowsStepsFields = [...flowsStepsFields, { flow: defaultFlowName, fields: _step?.fields }];
          }
        }
      });
    }

    if (!!flowsStepsFields?.length) {
      flowsStepsFields = flowsStepsFields?.filter((_flowsStepsFields) => !!_flowsStepsFields);
    }

    mainStepValidationFlows?.forEach((mainStepValidationFlow) => {
      const secondaryStepFlow = flowsStepsFields?.find((flowsStepsField) =>
        isEqual(flowsStepsField?.flow, mainStepValidationFlow),
      );

      // if the secondary step in is the same flow as the main step but doesn't have the specific field
      if (secondaryStepFlow && !secondaryStepFlow?.fields?.some((f) => isEqual(f?.field, fieldName))) {
        mainStepValidationFlowsNames.push(mainStepValidationFlow);
      }
    });
  }

  if (!!mainStepValidationFlowsNames?.length && mainStepValidationFlowsNames?.every((flowName) => !!flowName)) {
    mainStepValidationFlowsNames = formatListContainsOnlyOneItem(mainStepValidationFlowsNames);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidation)(mainStepValidationFlowsNames),
    );
  }
};

/* This is a hack to check if a component should be full attached with other component, in the same sequencial order.
  Until now used only in Smart Choice validation.
*/
export const isSequential = (array) => {
  if (array.length <= 1) {
    return true;
  }

  let isSequential = true;
  array.forEach((value, index) => {
    if (index > 0) {
      if (array[index] !== array[index - 1] + 1) {
        isSequential = false;
      }
    }
  });
  return isSequential;
};

export const verifyDuplicate = (array) => {
  const uniqueElements = new Set();

  for (const element of array) {
    if (uniqueElements.has(element)) {
      return true; // There is a duplicate
    }
    uniqueElements.add(element);
  }

  return false; // No duplicates
};
